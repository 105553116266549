import requests from '@/utils/request'

//拿到分类的接口
export const reqWeightCategory = (data) => {
  return requests({
    url: '/selector/category',
    method: 'get',
    params: data,
  })
}

// 拿到分类里面的商品
export const reqCategoryItems = (data) => {
  return requests({
    url: '/basedata/product/queryProductList',
    method: 'post',
    data,
  })
}
