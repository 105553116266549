import requests from '@/utils/request'

//挂单的接口
export const reqOrderHang = (data) => {
  return requests({
    url: '/retail/stag/save',
    method: 'post',
    data,
  })
}

// 删除当前挂单的接口
export const reqDeleteOrderHang = (data) => {
  return requests({
    url: '/retail/stag/delete',
    method: 'get',
    params: data,
  })
}

//取单的接口
export const reqOrderPickup = () => {
  return requests({
    url: '/retail/stag/list',
    method: 'get',
  })
}
