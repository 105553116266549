import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import { Message } from 'element-ui'
// import cashdesk  from '@/views/cashdesk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '国富兴泰收款',
    },
  },
  {
    path: '/cashdesk',
    name: 'cashDesk',
    component: () => import('../views/cashdesk.vue'),
    meta: {
      title: '国富兴泰收款',
    },
    beforeEach(to, from, next) {
      if (localStorage.getItem('TOKEN')) {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    },
  },
  {
    path: '/backgoods',
    name: 'backgoods',
    component: () => import('../views/backgoods.vue'),
    meta: {
      title: '国富兴泰收款',
    },
    beforeEach(to, from, next) {
      if (localStorage.getItem('TOKEN')) {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/registration/registration.vue'),
    meta: {
      title: '国富兴泰收款',
    },
    beforeEach(to, from, next) {
      if (localStorage.getItem('TOKEN')) {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    },
  },
  {
    path: '/weight',
    name: 'weight',
    component: () => import('../views/weight/weight.vue'),
    meta: {
      title: '国富兴泰收款',
    },
    beforeEach(to, from, next) {
      if (localStorage.getItem('TOKEN')) {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    },
  },
  {
    path: '/canteen',
    name: 'canteen',
    component: () => import('../views/canteen/index.vue'),
    meta: {
      title: '国富兴泰收款',
    },
    beforeEach(to, from, next) {
      if (localStorage.getItem('TOKEN')) {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
    // 这个路由需要验证，检查是否存在token
    if (!localStorage.getItem('TOKEN')) {
      // 没有登录，跳转向到登录页面
      next('/login')
      document.title = to.meta.title
    } else {
      // 已经登录，继续请求
      next()
      document.title = to.meta.title
    }
  } else {
    // 不需要验证的路由，直接通过
    next()
    document.title = to.meta.title
  }
})

export default router
