import { reqUserLogin, reqCode, reqUserLogout } from '@/api/login'

const state = {
  codeImg: '',
  sn: '',
  token: localStorage.getItem('TOKEN'),
  nickName: localStorage.getItem('NICKNAME'),
  // 商户id
  merchId: localStorage.getItem('MERCHID'),
  merchAddress: localStorage.getItem('MERCHADDRESS'),
}

const mutations = {
  SET_CODEIMG(state, codeImg) {
    state.codeImg = codeImg
  },
  SET_SN(state, sn) {
    state.sn = sn
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_USERINFO(state, nickName) {
    state.nickName = nickName
  },
  SET_MERCHID(state, merchId) {
    state.merchId = merchId
  },
  SET_MERCHADDRESS(state, merchAddress) {
    state.merchAddress = merchAddress
  },
}

const actions = {
  // 验证码
  async reqCode({ commit, state, dispatch }) {
    const result = await reqCode()
    // console.log(result)
    if (result.code === 200) {
      commit('SET_CODEIMG', result.data.image)
      commit('SET_SN', result.data.sn)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 登录
  async userLogin({ commit, state, dispatch }, data) {
    const result = await reqUserLogin(data)
    // console.log(result, 111)
    if (result.code === 200) {
      commit('SET_TOKEN', result.data.token)
      commit('SET_USERINFO', result.data.user.name)
      commit('SET_MERCHID', result.data.merchId)
      commit('SET_MERCHADDRESS', result.data.merchAddress)
      localStorage.setItem('TOKEN', result.data.token)
      localStorage.setItem('NICKNAME', result.data.user.name)
      localStorage.setItem('MERCHID', result.data.merchId)
      localStorage.setItem('MERCHADDRESS', result.data.merchAddress)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 退出登录
  async reqUserLogout({ commit, state, dispatch }) {
    const result = await reqUserLogout()
    // console.log(result)
    if (result.code === 200) {
      localStorage.clear()
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
