<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>

<style scoped>
#app {
  background-color: #fcfafa;
  height: 100vh;
}
</style>
