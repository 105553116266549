import {
  reqOrderHang,
  reqDeleteOrderHang,
  reqOrderPickup,
} from '@/api/registration'

const state = {
  // 取单 所有的挂单数据
  orderPickupList: [],
  // 取单 拿到当前这个单的数据
  orderPickupData: {},
}

const mutations = {
  // 取单
  SET_ORDERPICKUPLIST(state, orderPickupList) {
    state.orderPickupList = orderPickupList
  },
  // 接收数组 取单到收银页面
  SET_ORDERPICKUPDATA(state, orderPickupData) {
    state.orderPickupData = orderPickupData
    console.log(state.orderPickupData, 'state.orderPickupData')
  },
}

const actions = {
  // //挂单的接口
  async reqOrderHang({ commit, state, dispatch }, data) {
    const result = await reqOrderHang(data)
    if (result.code === 200) {
      return 'ok'
    } else {
      return Promise.reject(new Error(result.msg))
    }
  },

  // 删除当前挂单的接口
  async reqDeleteOrderHang({ commit, state, dispatch }, data) {
    const result = await reqDeleteOrderHang(data)
    if (result.code === 200) {
      return 'ok'
    } else {
      return Promise.reject(new Error(result.msg))
    }
  },

  //取单的接口 拿到所有的单的接口
  async reqOrderPickup({ commit, state, dispatch }) {
    const result = await reqOrderPickup()
    if (result.code === 200) {
      commit('SET_ORDERPICKUPLIST', result.data)
    }
  },
  // 接收数组 取单到收银页面
  getOrderPickupData({ commit, state, dispatch }, data) {
    commit('SET_ORDERPICKUPDATA', data)
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
