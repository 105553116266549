import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './user/index'
import cashdesk from './cashdesk'
import registration from './registration/index'
import weight from './weight/index'
import canteen from './canteen'

export default new Vuex.Store({
  modules: {
    user,
    cashdesk,
    registration,
    weight,
    canteen,
  },
})
