import { reqWeightCategory, reqCategoryItems } from '@/api/weight.js'
import {
  reqAdvanceOrder,
  reqAlipayAndWechatPay,
  reqFindDd,
} from '@/api/cashdesk'

const state = {
  canteenCateData: [],
  canteenCateDataItems: [],
  totalCount: 0,
  totalPage: 0,
  ordersn: '',
  orderid: '',
  // 预订单code
  advacneCode: '',
  advanceMsg: '',
  // 支付成功和失败的标识
  payCode: 0,
  msg: '',
  // 查单的code
  findDDCode: '',
}

const mutations = {
  SET_CANTEENCATEDATA(state, canteenCateData) {
    state.canteenCateData = canteenCateData
  },
  SET_CANTEENCATEDATATIEMS(state, canteenCateDataItems) {
    state.canteenCateDataItems = canteenCateDataItems
  },
  SET_TOTALCOUNT(state, totalCount) {
    state.totalCount = totalCount
  },

  SET_TOTAPAGE(state, totalPage) {
    state.totalPage = totalPage
  },
  // advacneCode: '',
  SET_ADVANCECODE(state, advacneCode) {
    state.advacneCode = advacneCode
  },
  // advanceMsg
  SET_ADVANCEMSG(state, advanceMsg) {
    state.advanceMsg = advanceMsg
  },
  SET_ORDERSN(state, ordersn) {
    state.ordersn = ordersn
  },
  SET_ORDERID(state, orderid) {
    state.orderid = orderid
  },
  SET_PAYCODE(state, payCode) {
    state.payCode = payCode
  },
  SET_PAYMSG(state, msg) {
    state.msg = msg
  },
  SET_FINDDDCODE(state, findDDCode) {
    state.findDDCode = findDDCode
  },
}

const actions = {
  // 请求分类的接口
  async reqCanteenCateData({ commit, state, dispatch }, data) {
    const result = await reqWeightCategory(data)
    if (result.code === 200) {
      commit('SET_CANTEENCATEDATA', result.data)
    }
  },
  async reqCanteenCateDataItems({ commit, state, dispatch }, data) {
    const result = await reqCategoryItems(data)
    if (result.code === 200) {
      commit('SET_CANTEENCATEDATATIEMS', result.data.datas)
      commit('SET_TOTALCOUNT', result.data.totalCount)
      commit('SET_TOTAPAGE', result.data.totalPage)
    }
  },
  // 请求预订单接口
  async reqCanteenAdvanceOrder({ commit, state, dispatch }, data) {
    const result = await reqAdvanceOrder(data)
    commit('SET_ADVANCECODE', result.code)
    commit('SET_ADVANCEMSG', result.msg)
    commit('SET_ORDERSN', result.data.ordersn)
    commit('SET_ORDERID', result.data.orderid)
    return 'ok'
  },
  // 反扫支付宝和微信支付接口
  async reqCanteenAlipayAndWechatPay({ commit, state, dispatch }, data) {
    const result = await reqAlipayAndWechatPay(data)
    commit('SET_PAYCODE', result.code)
    commit('SET_PAYMSG', result.message)
  },
  // 调订单同步接口
  async reqCanteenFindDd({ commit, state, dispatch }, data) {
    const result = await reqFindDd(data)
    commit('SET_FINDDDCODE', result.code)
  },
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
