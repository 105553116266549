<template>
  <div>
    <div>
      <img src="@/assets/login.jpg" alt="" class="image" />
    </div>
    <!-- @submit.native.prevent 阻止表单提交刷新 -->
    <el-row type="flex" justify="center" @submit.native.prevent>
      <el-col :span="12">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form">
          <el-form-item prop="username">
            <el-input v-model="ruleForm.username" placeholder="请输入账号">
              <i slot="prefix" class="el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              show-password
              autocomplete="off"
              placeholder="请输入密码"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="captcha" class="vertical">
            <el-input v-model="ruleForm.captcha" placeholder="请输入验证码">
              <template slot="suffix">
                <img :src="codeImg" @click="getCode" class="captcha-image" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="btnBcg"
              :disabled="!submitDisabled"
              native-type="submit"
              @keyup.enter="keyDown"
              @click="submitForm('ruleForm')"
              >登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</el-button
            >
          </el-form-item>
          <div class="divText">
            有任何问题请联系：4001033005 工作时间：周一至周六，上午8:30-下午6:00
          </div>
          <div class="divText">备案号：湘ICP备19008991号-3</div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      // 屏幕宽度
      windowWidth: window.innerWidth,
      codeImg: '',
      ruleForm: {
        username: '',
        password: '',
        captcha: '',
        sn: '',
      },
      rules: {
        username: [{ require: true, trigger: 'blur' }],
        password: [{ require: true, trigger: 'blur' }],
        captcha: [{ require: true, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getCode()
  },
  computed: {
    submitDisabled() {
      return (
        this.ruleForm.username &&
        this.ruleForm.password &&
        this.ruleForm.captcha
      )
    },
  },
  methods: {
    async getCode() {
      try {
        await this.$store.dispatch('reqCode')
        this.codeImg = this.$store.state.user.codeImg
        this.ruleForm.sn = this.$store.state.user.sn
      } catch (error) {
        return false
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            await this.$store.dispatch('userLogin', this.ruleForm)
            this.$router.push('/cashdesk')
          } catch (error) {
            this.getCode()
            this.ruleForm.captcha = ''
            return false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode === 13) {
        console.log('键盘按下登录')
        //需要执行的登录方法
        this.submitForm('ruleForm')
      }
    },
  },
}
</script>

<style scoped>
.image {
  width: 100%;
}
.title {
  display: flex;
  justify-content: center;
  margin: 80px 0 20px 0;
  font-size: 20px;
  font-weight: 700;
}

.form {
  margin-top: 20px;
}

.captcha-image {
  width: 80px;
  height: 40px;
  margin-right: -6px;
  border: none;
  /* background-color: pink; */
}

.login-code img {
  cursor: pointer;
  vertical-align: middle;
  width: 80px;
  height: 39px;
}

.btnBcg {
  width: 100%;
  background-color: #d4331f !important;
  color: white !important;
  font-size: 20px;
}

.divText {
  text-align: center;
  color: #b1b5ad;
  font-size: 12px;
  margin-bottom: 20px;
}
</style>
