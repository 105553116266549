import requests from '@/utils/request'
// 登录的接口
export const reqUserLogin = (data) => {
  return requests({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/auth/login',
    method: 'post',
    data,
  })
}

// 验证码的接口
export const reqCode = () => {
  return requests({ url: '/auth/captcha', method: 'get' })
}

// 退出登录的接口
export const reqUserLogout = () => {
  return requests({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/auth/logout',
    method: 'post',
  })
}
