import requests from '@/utils/request'
// 获取所有商品的信息
export const reqAllgoods = (data) => {
  return requests({
    url: '/basedata/product/queryRetail',
    method: 'post',
    data,
  })
}
// 仓库id的接口
export const reqScIdList = () => {
  return requests({
    url: '/selector/sc',
    method: 'get',
  })
}

// 预订单接口
export const reqAdvanceOrder = (data) => {
  return requests({
    url: '/index/order/preOrderNew',
    method: 'post',
    data,
    baseURL: '/shopapi',
  })
}

// 反扫 微信和支付宝的接口
export const reqAlipayAndWechatPay = (data) => {
  return requests({
    url: '/system/payment/payWxQrcodes',
    method: 'get',
    params: data,
    baseURL: '/shopapi',
  })
}

// 语音播报器接口
export const reqAudioRead = (data) => {
  return requests({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/index/merch/getcloud',
    method: 'post',
    data,
    baseURL: '/audioread',
  })
}

// 调订单同步接口
export const reqFindDd = (data) => {
  return requests({
    url: '/system/payment/findDd',
    method: 'get',
    params: data,
    baseURL: '/shopapi',
  })
}

// 正扫出库接口
export const scanningOutbound = (data) => {
  return requests({
    url: '/index/order/createFinishOrder',
    method: 'post',
    data,
    baseURL: '/shopapi',
  })
}

// printReceipts
export const printReceipts = (data) => {
  return requests({
    url: '/mqttPrint',
    method: 'post',
    data,
    baseURL: '/printapi',
  })
}
