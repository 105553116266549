import { reqWeightCategory, reqCategoryItems } from '@/api/weight.js'

const state = {
  // 收银页面 扫码的数据 存起来 先扫码的数组 然后加上称重的数据
  originBuyGoodData: [],
  // 加上称重的数据之后的购买商品的数组
  addWeightBuyGoodData: [],
  // 分类的数组
  categoryList: [],
  categoryItemsData: [],
}

const mutations = {
  // 收银页面 扫码的数据 存起来 先扫码的数组 然后加上称重的数据
  SET_ORIGINBUYGOODDATA(state, originBuyGoodData) {
    state.originBuyGoodData = originBuyGoodData
  },
  // 加上称重的数据之后的购买商品的数组
  SET_ADDWEIGHTBUYGOODDATA(state, addWeightBuyGoodData) {
    state.addWeightBuyGoodData = addWeightBuyGoodData
  },
  // 分类的数组
  SET_CATEGORYLIST(state, categoryList) {
    state.categoryList = categoryList
  },
  SET_CATEGORYITEMSDATA(state, categoryItemsData) {
    state.categoryItemsData = categoryItemsData
  },
}

const actions = {
  setOriginBuyGoodData({ commit }, data) {
    commit('SET_ORIGINBUYGOODDATA', data)
  },
  setAddWeightBuyGoodData({ commit, state }, data) {
    // 合并原数组和要添加的新数组对象
    const mergedItems = [...state.originBuyGoodData, ...data]
    console.log(state.originBuyGoodData, 'state.originBuyGoodData')
    console.log(data, 'data')
    console.log(mergedItems, 'mergedItems')
    commit('SET_ADDWEIGHTBUYGOODDATA', mergedItems)
  },
  // 请求分类的接口
  async reqWeightCategory({ commit, state, dispatch }, data) {
    const result = await reqWeightCategory(data)
    if (result.code === 200) {
      commit('SET_CATEGORYLIST', result.data)
    }
  },
  async reqCategoryItems({ commit, state, dispatch }, data) {
    const result = await reqCategoryItems(data)
    if (result.code === 200) {
      commit('SET_CATEGORYITEMSDATA', result.data)
    }
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
